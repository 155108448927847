import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [];

export const dictionary = {
		"/": [10],
		"/auth/signup": [27],
		"/(public)/forgot-password": [23,[8],[9]],
		"/(public)/login": [24,[8],[9]],
		"/(app)/photos": [11,[2,4],[3]],
		"/(app)/photos/[gallery]": [12,[2,4,5],[3]],
		"/(app)/photos/[gallery]/[photo]": [13,[2,4,5],[3]],
		"/(app)/portfolio": [14,[2,6],[3]],
		"/(app)/portfolio/design": [15,[2,6],[3]],
		"/(app)/portfolio/navigation": [16,[2,6],[3]],
		"/(app)/profile": [17,[2],[3]],
		"/(public)/reset-password": [25],
		"/(app)/settings": [18,[2],[3]],
		"/(public)/signup": [26,[8],[9]],
		"/(app)/welcome": [19,[2,7],[3]],
		"/(app)/welcome/continue": [20,[2,7],[3]],
		"/(app)/welcome/gallery": [21,[2,7],[3]],
		"/(app)/welcome/photos": [22,[2,7],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';